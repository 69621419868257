import { ICustomizations } from "./providers/types";

interface ITargeting {
  mobile: boolean
  size: [number, number]
}

interface IBid<P = unknown> {
  bidder: string
  params: P
}

function makeBid(bidder: string, params: unknown): IBid {
  return { bidder, params };
}

type IBidder = (params: any, bids: IBid[], targeting: ITargeting) => void

const bidders: {[bidder: string]: IBidder} = {
  ix: (params: ICustomizations['ix'], bids: IBid[], targeting: ITargeting) => {
    const siteId = targeting.mobile ? params.siteIdM : params.siteIdD
    bids.push(makeBid('ix', { siteId }))
  },
  pubmatic: (params: ICustomizations['pubmatic'], bids: IBid[], targeting: ITargeting) => {
    bids.push(makeBid('pubmatic', targeting.mobile ? {
      publisherId: params.pubIdM,
      adSlot: params.adSlotM
    } : targeting.size[0] === 728 ? {
      publisherId: params.pubIdB,
      adSlot: params.adSlotB
    } : {
      publisherId: params.pubIdD,
      adSlot: params.adSlotD
    }))
  },
  yahoossp: (params: ICustomizations['yahoossp'], bids: IBid[], targeting: ITargeting) => {
    bids.push(makeBid('yahoossp', targeting.mobile ? {
      dcn: params.dcnM,
      pos: params.posM
    } : targeting.size[0] === 728 ? {
      dcn: params.dcnB,
      pos: params.posB
    } : {
      dcn: params.dcnD,
      pos: params.posD
    }))
  },
  amx: (params: ICustomizations['amx'], bids: IBid[], targeting: ITargeting) => {
    bids.push(makeBid('amx', targeting.mobile ? { tagId: params.tagIdM } : { tagId: params.tagIdD }))
  },
  appnexus: (params: ICustomizations['appnexus'], bids: IBid[], targeting: ITargeting) => {
    bids.push(makeBid('appnexus', targeting.mobile ? { placementId: params.placementIdM } : { placementId: params.placementIdD }))
  },
  mediafuse: (params: ICustomizations['mediafuse'], bids: IBid[], targeting: ITargeting) => {
    bids.push(makeBid('mediafuse', targeting.mobile ? { placementId: params.placementIdM } : { placementId: params.placementIdD }))
  },
  rubicon: (params: ICustomizations['rubicon'], bids: IBid[], targeting: ITargeting) => {
    bids.push(makeBid('rubicon', targeting.mobile ? { accountId: params.accountId, siteId: params.siteIdM, zoneId: params.zoneIdM } : { accountId: params.accountId, siteId: params.siteIdD, zoneId: params.zoneIdD }))
  },
}

export function makeBidFactory(theme: ICustomizations) {
  return (targeting: ITargeting): IBid[] => {
    const bids: IBid[] = []

    for (const bidder in bidders) {
      const params = theme[bidder as keyof ICustomizations]
      if (params) {
        bidders[bidder](params, bids, targeting)
      }
    }

    return bids
  }
}
